import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { css } from '@emotion/core';
import { OrderContext } from './order-context';
import logo from '../images/logo.png';

//https://mycolor.space/?hex=%23845EC2&sub=1
// @media only screen and (min-width: 768px) {
//   grid-template-columns: auto 300px auto;
// }
const Header = ({ siteTitle }) => {
  const orders = useContext(OrderContext);
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        padding: 10px;
        font-family: 'Open Sans', sans-serif;
      `}
    >
      <div
        css={css`
          max-width: 200px;
        `}
      >
        <Link to="/">
          <img src={logo} alt="Banana Park Logo" />
        </Link>
      </div>

      <div
        css={css`
          text-align: right;
        `}
      >
        <Link
          css={css`
            color: #888;
          `}
          to="/order/"
        >
          {orders && orders.length
            ? `Orders: ${orders.length}`
            : `No orders yet`}
        </Link>
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
