import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import css from '@emotion/css';

import Header from './header';
import './layout.css';

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div
            css={css`
              margin: 0 auto;
              max-width: 960px;
              padding: 0px 1.0875rem 1.45rem;
              padding-top: 0;
              font-family: 'Open Sans', sans-serif;
            `}
          >
            {children}
            <footer
              css={css`
                margin: auto;
                text-align: center;
                font-size: 13px;
              `}
            >
              © {new Date().getFullYear()} banana park 🍌🏞
            </footer>
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
